<template>
  <div class="claimequity-container">
    <div>
      <template v-if="claimObj && !spinShow">
        <div class="nav">
          <router-link to="/user/claim">学者认领</router-link>
        </div>
        <div class="title">
          <p>系统检测到与您同名的学者，请确认身份 - 是您吗？</p>
          <p>告诉我们这些学术成果哪些是您的，以便将成果添加到您的个人资料中。</p>
        </div>
        <div class="item">
          <div class="header">
            <div class="left">
              <img
                v-if="claimObj.headPhoto"
                :src="$global.imgUrl + claimObj.headPhoto"
                alt=""
              />
              <img
                class="photo"
                v-else
                :src="require('@/assets/imgs/head_bg.png')"
              />
              <div class="info" v-if="claimObj.realname || claimObj.unit">
                <p class="top">{{ claimObj.realname }} - {{ claimObj.unit }}</p>
                <p class="bottom" v-if="claimObj.thesisPageInfo">
                  学术成果：{{ claimObj.thesisPageInfo.total }}
                </p>
              </div>
            </div>
            <div class="right">
              <Button class="auther" v-throttleClick @click="openModal">我是作者</Button>
              <Button class="noauther" v-throttleClick @click="changePage">这不是我</Button>
            </div>
          </div>
          <ul class="box" v-if="claimObj.thesisPageInfo">
            <li v-for="(v, i) in claimObj.thesisPageInfo.list" :key="i">
              <div class="title">{{ v.title }}</div>
              <div class="content">
                {{ v.authorList }}，{{ v.mettingName }}，{{ v.mettingTime }}
              </div>
            </li>
          </ul>
        </div>
        <certificationModal :modalShow ="showModal" :hid="claimObj.userId" :isHonor="'学者'" @closeModal="showModal = false"/>
      </template>
      <div class="no-results"  v-if="!claimObj && !spinShow">
        <img :src="require('@/assets/qietu/article.png')" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
    <Spin size="large" fix v-show="spinShow"></Spin>
  </div>
</template>

<script>
import certificationModal from "../../components/certificationModal.vue";
export default {
  components: {
    certificationModal
  },
  name: "index",
  data() {
    return {
      showModal: false,
      spinShow: false,
      claimObj: {},
    };
  },
  created() {
    this.recommendScholar();
  },
  methods: {
    openModal() {
      if (this.claimObj.reviewStatus === 4) {
        this.$Message.warning('已有认领申请等待审核中')
        return
      }
      this.showModal = true
    },
    async changePage() {
      const resp = await this.$apis.userServe.notRecommend({
        blackUserId: this.claimObj.userId,
      });
      console.log(resp);
      this.recommendScholar();
    },
    async recommendScholar() {
      this.spinShow = true
      const resp = await this.$apis.userServe.recommendScholar();
      this.spinShow = false
      this.claimObj = resp.data;
      // 处理后端返回的数据带null情况
      if(this.claimObj.thesisPageInfo && this.claimObj.thesisPageInfo.list && this.claimObj.thesisPageInfo.list.length) {
        this.claimObj.thesisPageInfo.list.map(item => {
          if(item.authorList) {
            let arr = item.authorList.split(',')
            arr = arr.filter(val => val !== 'null')
            item.authorList = arr.join(',')
          }
          if(item.mettingTime) {
            let arr = item.mettingTime.split('第')
            arr = arr.filter(val => !val.includes('null'))
            item.mettingTime = arr.join('第')
            if(item.mettingTime && !item.mettingTime.includes('年')) {
              item.mettingTime = '第' + item.mettingTime
            }
          }
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-results {
  margin: 0 auto;
  width: 500px;
  img {
    width: 100%;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #999999;
  }
}
::v-deep .ivu-modal {
  p {
    margin: 14px 0;
  }
}
::v-deep .ivu-upload .ivu-upload-list {
  // vertical-align: middle;
  // display: inline-block;
}
.picture {
  .demo-upload-list {
    display: inline-block;
    width: 134px;
    height: 134px;
    text-align: center;
    line-height: 134px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .demo-upload-list:hover .demo-upload-list-cover {
    display: block;
  }
  .demo-upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }
}
.file-container {
  ::v-deep .ivu-upload-list-file {
    span {
        display: inline-block;
        max-width: 90%;
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
  }
  .demo-upload-list {
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
    width: 55px;
    overflow: hidden;
    display: inline-block;
    margin: 0 7px;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
.claimequity-container {
  position: relative;
  min-height: 571px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 40px;
  padding-top: 20px;
  .nav {
    height: 48px;
    border-bottom: 1px solid #e6e6e6;
    a {
      width: 160px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      &.router-link-exact-active::after {
        content: "";
        display: block;
        width: 160px;
        height: 8px;
        background-image: url("~@/assets/imgs/xz.png");
        background-size: 100% 100%;
      }
    }
  }
  .title {
    p:first-child {
      font-size: 28px;
      color: #00a4ff;
      margin-top: 35px;
    }
    p:last-child {
      font-size: 16px;
      color: #999999;
      margin-top: 15px;
    }
  }
  .item {
    min-height: 571px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    margin-top: 30px;
    padding-bottom: 35px;
    .header {
      padding: 30px 25px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed #d6d6d6;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 56px;
          height: 56px;
          background: #f6f6f6;
          border: 1px solid #f6f6f6;
          border-radius: 50%;
          margin-right: 12px;
        }
        .info {
          .top {
            font-size: 28px;
            color: #333333;
          }
          .bottom {
            font-size: 14px;
            color: #999999;
          }
        }
      }
      .right {
        .auther {
          width: 160px;
          height: 40px;
          border: 1px dashed #00a4ff;
          border-radius: 8px;
          margin-right: 20px;
        }
        .noauther {
          width: 160px;
          height: 40px;
          border: 1px dashed #333333;
          border-radius: 8px;
        }
      }
    }
    .box {
      padding: 0 25px;
      li {
        padding: 24px 0;
        border-bottom: 1px dashed #d6d6d6;
        .title {
          font-size: 20px;
          color: #333333;
        }
        .content {
          margin-top: 18px;
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
}
</style>

