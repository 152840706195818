<template>
  <Modal v-model="show" width="710" :mask-closable="false">
        <div slot="header">
            <h4 class="modal-title">{{`${isHonor}证明材料`}}</h4>
        </div>
        <div class="content-box">
            <div class="tishi1">图片（支持JPG、JPEG、PNG格式，图片不超过 10M）{{'('+formData.evidencePhotos.length+'/5)'}}</div>
            <div class="demo-upload-list" v-for="(item, index) in formData.evidencePhotos" :key="index">
                <img v-lazy="$global.imgUrl + item.resultPass" alt="" />
                <div class="demo-upload-list-cover">
                    <Icon type="ios-trash-outline" @click.native="removePhotos(index)" />
                </div>
            </div>
            <Upload
                class="photoUplode"
                ref="upload"
                type="drag"
                accept=".png, .jpg, .jpeg"
                :format="['jpg', 'jpeg', 'png']"
                :max-size="10240"
                :headers="{ token: token }"
                :action="`${$global.url1}/zw-public/web/common/upload`"
                :show-upload-list="false"
                :on-exceeded-size="beyondSize"
                :on-success="PhotoUplode"
                :before-upload="beforeUplode"
            >
                <div class="photoUplodeBox">
                    <Icon type="ios-camera" size="20"></Icon>
                </div>
            </Upload>
            <div class="tishi1">文件（支持PDF格式，文件不超过 30M）{{'('+formData.evidenceFiles.length+'/5)'}}</div>
            <Upload
                ref="files"
                class="files"
                accept=".pdf"
                :format="['pdf']"
                :headers="{ token: token }"
                :max-size="30720"
                :action="`${$global.url1}/zw-public/web/common/upload`"
                :on-exceeded-size="beyondSize"
                :on-success="uplodeFile"
                :on-remove="removeFile"
                :before-upload="beforeFilesUplode"
            >
                <Button class="filesBtn">选择文件</Button>
            </Upload>
        </div>
        <div slot="footer">
            <Button v-throttleClick="2000" class="Btn" @click="submit">提交</Button>
        </div>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: {
        isHonor: {
            type: String,
            required: true
        },
        modalShow: {
            tyep: Boolean,
            default: false
        },
        hid: [String,Number]
    },
    computed: {
        ...mapState(["token"]),
        show: {
            get() {
                return this.modalShow
            },
            set() {
                this.close()
            }
        },
    },
    data() {
        return {
            formData: {
                evidencePhotos: [],
                evidenceFiles: [],
            },
        }
    },
    methods: {
         // 超出文件上传大小
        beyondSize(file) {
            this.$Message.warning({
                content: file.name + " 文件大小超出限制,请重新上传",
                duration: 2,
            });
        },
        // 图片上传
        beforeUplode() { // 上传之前
            if (this.formData.evidencePhotos.length >= 5) {
                this.$Notice.warning({
                    title: "上传图片已达上限",
                    duration: 1.5
                });
                return false
            }
        },
        PhotoUplode(res) { // 上传成功
            const url = res.content.url;
            this.formData.evidencePhotos.push({
                resultPass: url,
                resultType: 1,
            });
        },
        removePhotos(index) { // 删除图片
            this.formData.evidencePhotos.splice(index, 1);
        },
        // 文件上传
        beforeFilesUplode() {
            if (this.formData.evidenceFiles.length >= 5) {
                this.$Notice.warning({
                    title: "上传文件已达上限",
                    duration: 1.5
                });
                return false
            }
        },
        uplodeFile(res,file) { // 上传成功钩子
            const url = res.content.url;
            this.formData.evidenceFiles.push({
                resultPass: url,
                resultName: file.name,
                resultType: 2,
            });
        },
        removeFile(file) { // 移除文件钩子
            this.formData.evidenceFiles = this.formData.evidenceFiles.filter(item => item.resultPass !== file.response.content.url)
        },
        // 提交表单
        async submit() {
            if(this.formData.evidencePhotos.length > 0 || this.formData.evidenceFiles.length > 0) {
                const {data} = this.isHonor !== '学者' ?  await this.$apis.userServe.honourAuth({
                    hid: this.hid,
                    userHonourFileList: [...this.formData.evidencePhotos,...this.formData.evidenceFiles]
                }) : await this.$apis.userServe.claimScholar({
                    evidenceFiles: this.formData.evidenceFiles.map(item => ({fileName: item.resultName,fileUri: item.resultPass})),
                    evidencePhotos: this.formData.evidencePhotos.map(item => item.resultPass),
                    claimedUserId: this.hid,
                });
                if (data.resultCode === '201') {
                    this.$Message.error(data.content.msg)
                } else {
                    this.$Message.success('提交成功')
                    this.close()
                }
            } else {
                this.$Message.error('请上传证明材料!');
            }
        },
        // 关闭Modal
        close() {
            this.formData = {
                evidencePhotos: [],
                evidenceFiles: [],
            }
            this.$refs.files.clearFiles()
            this.$emit("closeModal")
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .ivu-modal-content {
    padding: 40px;
    border-radius: 18px;
    overflow: hidden;
    .ivu-modal-close {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #00A4FF;
        border-radius: 0px 0px 0px 18px;
        top: 0;
        right: 0;
        .ivu-icon {
            top: 5px;
            color: #fff;
            font-size: 32px;
        }
    }
    .ivu-modal-header {
        position: relative;
        padding: 0;
        border: none;
        .modal-title {
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #333333;
        }
        .modal-subtitle {
            margin-top: 10px;
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #999999;
        }
    }
    .ivu-modal-body {
        padding: 0;
        margin-top: 30px;
        .content-box {
            .ivu-form-item-label {
                font-size: 16px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #666666;
            }
            .ivu-input {
                height: 40px;
            }
            .shenfenUpload {
                display: inline-block;
                width: 300px;
                height: 188px;
                .ivu-upload {
                    width: 100%;
                    height: 100%;
                    .IDimg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .tishi {
                font-size: 14px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #999999;
            }
            .tishi1 {
                margin-bottom: 14px;
                font-size: 14px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #333333; 
            }
            .files {
               .ivu-icon-ios-close {
                   margin-top: 7px;
               }
            }
            .filesBtn {
                width: 120px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #00A4FF;
                border-radius: 8px;
                font-size: 14px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #00A4FF;
            }
            .photoUplode {
                display: inline-block;
                width: 134px;
                height: 134px;
                .ivu-upload {
                    width: 100%;
                    height: 100%;
                    .photoUplodeBox {
                        width: 100%;
                        height: 100%;
                        line-height: 134px;
                    }
                }
            }
            .demo-upload-list {
                position: relative;
                display: inline-block;
                overflow: hidden;
                margin-right: 4px;
                width: 134px;
                height: 134px;
                text-align: center;
                line-height: 134px;
                border: 1px solid transparent;
                border-radius: 4px;
                background: #fff;
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
                img {
                    width: 100%;
                    height: 100%;
                }
                .demo-upload-list-cover {
                    display: none;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: rgba(0, 0, 0, 0.6);
                    i {
                        margin: 0 2px;
                        color: #fff;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
            }
            .demo-upload-list:hover .demo-upload-list-cover {
                display: block;
            }
        }
    }
    .ivu-modal-footer {
        padding: 0;
        border-top: none;
        .Btn {
            width: 140px;
            height: 40px;
            background: #00A4FF;
            border-radius: 8px;
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .ivu-upload-list-file {
        span {
            display: inline-block;
            max-width: 90%;
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }
}
</style>